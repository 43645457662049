<template>
  <div style="margin-bottom: 0.5em;">
    <div class="ma-0 pa-0" style="display: block; position: absolute; left:0; right: 0; height: 0.5em; background-color: inherit; margin-top: -0.5em !important;"></div>
  </div>
</template>

<script>
export default {
  data: () => ({})
}
</script>

<style scoped>

</style>
