<template>
  <section>
    <div style="position: relative;" class="no-select">
    <v-container style="position: absolute; top:0; left: 50%; transform: translate(-50%); height: 100%;">
      <div class="hidden-sm-and-down">
        <div class="nav-buttons ml-2" style="left: 0 !important;">
          <v-btn icon @click="doGoPrev" class="white elevation-10"><font-awesome-icon :icon="['fas', 'arrow-left']"/></v-btn>
        </div>
        <div class="nav-buttons mr-2" style="right: 0 !important;">
          <v-btn icon @click="doGoNext" class="white elevation-10"><font-awesome-icon :icon="['fas', 'arrow-right']"/></v-btn>
        </div>
      </div>
    </v-container>
    <hooper ref="carousel" :settings="carouselSettings" :autoPlay="!howTo" :playSpeed="7000" style="height: 100%;" :class="showingHowTo ? 'blurry' : ''" @beforeSlide="beforeSlide" @afterSlide="afterSlide">
      <slide v-for="(course, index) in courses" :key="index" :index="index" class="pr-8 pb-6">
        <div class="rounded-lg mr-4" style="position: relative;">
          <v-img class="rounded-lg" :aspect-ratio="1.2" :src="(course.images[0] !== undefined && 'url' in course.images[0] ? course.images[0].url : `https://via.placeholder.com/1920x1080.png?text=${course.name} Course`)">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-row>
            </template>
              <v-overlay :absolute="true" :opacity="0.4" z-index="4" class="primaryCourse insetShadow">
                <div style="position: relative; width: 100%; height: 100%;">
                  <div>
                    <v-avatar size="58" class="ma-2">
                      <v-img contain :src="course.organization.logoUrl"></v-img>
                    </v-avatar>
                    <span class="font-weight-bold">{{ course.organization.name }}</span>
                  </div>
                  <div class="pa-4" style="position: absolute; top: 0; right: 0;">
                    <outlined-white-button @click="doGoToCourse(course.id)">Explore</outlined-white-button>
                  </div>
                  <div class="pa-4" style="position: absolute; bottom: 0;">
                    <div class="goBold-4 mt-1 mb-3">
                      <p v-snip="{ lines: 2, mode: 'js', midWord: true }">{{ course.name }}</p>
                    </div>
                    <div class="gothic-1 mt-1">
                      <p v-snip="{ lines: 3, mode: 'js', midWord: true }">{{ course.subtitle }}</p>
                    </div>
                  </div>
                </div>
              </v-overlay>
          </v-img>
        </div>
      </slide>
    </hooper>
    <div v-intersect="onIntersect"></div>
    <v-fade-transition>
      <div v-show="this.showingHowTo" style="position: absolute; top:0; left:0; width: 100%; height: 100%; background: rgba(255,255,255,0);" class="text-center">
        <div class="Absolute-Center">
          <div ref="handPointer">
            <font-awesome-icon  :icon="`fa-solid ${mobile ? 'fa-hand-point-up' : 'fa-arrow-pointer'}`" :size="`${mobile ? '6x' : '4x'}`" class="white--text handPointer"/>
          </div>
        </div>
      </div>
    </v-fade-transition>
    </div>
  </section>
</template>

<script>
import { Hooper, Slide } from 'hooper'
import 'hooper/dist/hooper.css'
import OutlinedWhiteButton from '@/components/outlinedWhiteButton.vue'
export default {
  name: 'topCarouselList',
  components: {
    OutlinedWhiteButton,
    Hooper,
    Slide
  },
  props: {
    courses: {
      type: Array
    }
  },
  data: () => ({
    howTo: true,
    mobile: false,
    showingHowTo: false,
    currentlySliding: false,
    carouselSettings: {
      centerMode: true,
      wheelControl: false,
      infiniteScroll: true,
      breakpoints: {
        600: {
          itemsToShow: 2
        },
        960: {
          itemsToShow: 3.5
        },
        1000: {
          itemsToShow: 2.5
        },
        1264: {
          itemsToShow: 2.1
        },
        1904: {
          itemsToShow: 3.1
        }
      }
    }
  }),
  methods: {
    onIntersect (entries, observer) {
      if (entries[0].isIntersecting && this.howTo) {
        this.$refs.handPointer.classList.add('animate-glide-left')

        this.showingHowTo = true

        this.$refs.carousel.config.transition = 2200

        setTimeout(() => {
          this.doGoNext()
        }, 100)

        setTimeout(() => {
          this.howTo = false
          this.showingHowTo = false
          this.$refs.carousel.config.transition = 700
        }, 2500)
      }
    },
    beforeSlide () {
      this.currentlySliding = true
    },
    afterSlide () {
      this.currentlySliding = false
    },
    doGoToCourse (id) {
      if (!this.currentlySliding) {
        this.$router.push({ name: 'view-course', params: { id: id } })
      }
    },
    doGoNext () {
      this.$refs.carousel.slideNext()
    },
    doGoPrev () {
      this.$refs.carousel.slidePrev()
    },
    mobileSet () {
      const width = (window.innerWidth > 0) ? window.innerWidth : screen.width

      if (width <= 960) {
        this.mobile = true
      }
    }
  },
  mounted () {
    this.mobileSet()
  }
}
</script>

<style scoped>
.blurry {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  cursor: pointer !important;
  pointer-events: none !important;
}

.nav-buttons {
  position: absolute;
  top: -30%;
}

.Absolute-Center {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%);
}

.title-gradient-background {
  background: rgb(2,0,36);
  background: linear-gradient(135deg, rgba(2,0,36,0.5) 0%, rgba(255,255,255,0.3) 100%);
}

.animate-glide-left {
  animation-delay: 0.5s;
  animation: glide-left 1s ease-in;
  -webkit-animation: glide-left 1s ease-in;
}

::v-deep .handPointer path {
  stroke: black;
  stroke-width: 10;
}

@keyframes glide-left {
  0% { transform: translateX(0%); }
  80% { transform: translateX(-100px) rotate(-15deg);}
  100% { transform: translateX(0%) rotate(0); }
}

.insetShadow {
  -webkit-box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
  -moz-box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
  box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
}
</style>
