<template>
  <v-parallax :src="poster" class="expanded" style="height: 400px;">
    <v-overlay :absolute="true" :opacity="0.8">
      <div class="pa-10 text-center">
        <div class="goBold-5 pb-4">
          {{ title }}
        </div>
        <div class="gothic-2">
          {{ description }}
        </div>
        <div class="mt-6">
          <outlined-white-button class="mt-2" @click="doClickRoute">Dive into our next trip</outlined-white-button>
        </div>
      </div>
    </v-overlay>
  </v-parallax>
</template>

<script>
import OutlinedWhiteButton from '@/components/outlinedWhiteButton.vue'

export default {
  name: 'QuickInfoAction',
  components: { OutlinedWhiteButton },
  props: {
    title: {
      type: String,
      default: () => 'Title'
    },
    description: {
      type: String,
      default: () => 'This is the short 1 sentence description for the action below'
    },
    poster: {
      type: String,
      default: () => 'https://s3.us-east-1.wasabisys.com/sea2sea/media/cozumel.jpg'
    },
    routeToName: {
      type: String,
      default: () => 'home'
    },
    routeParams: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    doClickRoute () {
      this.$router.push({ name: this.routeToName, params: this.routeParams })
    }
  }
}

</script>

<style scoped>

</style>
